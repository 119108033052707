import React, { Component } from 'react';

class ProjectSix extends Component {
    render() {

        return (



            <div class="hidden hidden-ball">


                <main>
                    <div class="preloader-wrap" data-firstline="Stay" data-secondline="Relaxed">

                        <div class="outer">
                            <div class="inner">
                                <div class="preloader-marquee-mask">
                                    <div class="preloader-marquee-wrapper">
                                        <div class="preloader-marquee" data-text="Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio Website">Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio Website Loading Gerardo M. Portfolio Website</div>
                                    </div>
                                </div>

                                <div class="preloader-marquee-wrapper stroked">
                                    <div class="preloader-marquee" data-text="Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio">Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio Loading Gerardo M. Portfolio</div>
                                </div>

                                <div class="percentage-wrapper"><div class="percentage" id="precent"></div></div>
                                <div class="trackbar">
                                    <div class="loadbar"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cd-index cd-main-content">

                        <div id="page-content" class="light-content layered-hero" data-bgcolor="#141414">

                            <header class="fullscreen-menu">
                                <div id="header-container">


                                    <div id="logo" class="hide-ball">
                                        <a class="ajax-link" data-type="page-transition" href="/main.html">
                                            <h1> GM </h1>
                                        </a>
                                    </div>


                                    <nav>
                                        <div class="nav-height">
                                            <div class="outer">
                                                <div class="inner">
                                                    <ul data-breakpoint="10025" class="flexnav">
                                                        <li class="link menu-timeline"><a href="#"><div class="before-span"><span data-hover="Portfolio">Portfolio</span></div></a>
                                                            <ul>
                                                                <li><a class="ajax-link" href="index.html" data-type="page-transition">Creative Carousel</a></li>
                                                                <li><a class="ajax-link" href="index-showcase.html" data-type="page-transition">Fullscreen Slider</a></li>
                                                                <li><a class="ajax-link" href="index-portfolio-grid.html" data-type="page-transition">Portfolio Grid</a></li>
                                                            </ul>
                                                        </li>
                                                        <li class="link menu-timeline"><a class="ajax-link" data-type="page-transition" href="about.html"><div class="before-span"><span data-hover="About">About</span></div></a></li>
                                                        <li class="link menu-timeline"><a class="ajax-link" data-type="page-transition" href="contact.html"><div class="before-span"><span data-hover="Contact">Contact</span></div></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>


                                    <div class="button-wrap right  menu">
                                        <div class="icon-wrap parallax-wrap">
                                            <div class="button-icon parallax-element">
                                                <div id="burger-wrapper">
                                                    <div id="menu-burger">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="button-text sticky right"><span data-hover="Menu">Menu</span></div>
                                    </div>

                                </div>
                            </header>




                            <div id="content-scroll">


                                <div id="main">

                                    <div id="hero" class="has-image autoscroll">
                                        <div id="hero-styles">
                                            <div id="hero-caption" class="reverse-parallax-onscroll">
                                                <div class="inner">
                                                    <div class="hero-title"><span>Cairs Root</span></div>
                                                    <div class="hero-subtitle">Product Design</div>
                                                </div>
                                            </div>
                                            <div id="hero-footer">
                                                <div class="hero-footer-left">
                                                    <div class="button-wrap left disable-drag scroll-down">
                                                        <div class="icon-wrap parallax-wrap">
                                                            <div class="button-icon parallax-element">
                                                                <i class="fa fa-angle-down"></i>
                                                            </div>
                                                        </div>
                                                        <div class="button-text sticky left"><span data-hover="Scroll or drag to navigate">Scroll or drag to navigate</span></div>
                                                    </div>
                                                </div>
                                                <div class="hero-footer-right">
                                                    <div id="share" class="page-action-content disable-drag" data-text="Share Project:"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="hero-image-wrapper">
                                        <div id="hero-background-layer" class="parallax-scroll-effect">
                                            <div id="hero-bg-image" style="background-image:url(images/06hero.jpg)"></div>
                                        </div>
                                    </div>


                                    <div id="main-content">
                                        <div id="main-page-content">

                                            <div class="vc_row row_padding_top row_padding_bottom default">

                                                <div class="two_third">
                                                    <h4 class="has-mask" data-delay="0">The Concept</h4>
                                                    <p class="has-animation" data-delay="400">The Root is a multifunctional product of humidifier and air purifier to provide an optimum level of humidity and clean air quality. Many countries have very humid summers and dry winters. It is same as many regions in the similar latitude.</p>
                                                </div>

                                                <div class="one_third last text-align-right">
                                                    <hr />
                                                    <div class="button-wrap right disable-drag has-animation" data-delay="350">
                                                        <div class="icon-wrap parallax-wrap">
                                                            <div class="button-icon parallax-element">
                                                                <i class="fa fa-angle-right"></i>
                                                            </div>
                                                        </div>
                                                        <a target="_blank" href="https://www.behance.net/gallery/24817263/ROOT"><div class="button-text sticky right"><span data-hover="Read More">Read More</span></div></a>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="vc_row large">

                                                <div class="row-half-color first half-dark-section" data-bgcolor="#141414"></div>
                                                <div class="row-half-color second half-white-section change-header-color" data-bgcolor="#fff"></div>

                                                <figure class="has-animation has-scale-vertical" data-delay="0">
                                                    <img src="images/projects/cairs01.jpg" alt="Image" />
                                                </figure>

                                            </div>


                                            <div class="vc_row white-section change-header-color" data-bgcolor="#fff">

                                                <hr /><hr /><hr />

                                                <div class="one_half"></div>

                                                <div class="one_half last">
                                                    <h4 class="has-mask" data-delay="0">Design for Better Performance</h4>
                                                    <p class="has-animation" data-delay="400">Unlike most of similar products to be placed in the corner, it was designed to be placed at the center of a living room.</p>
                                                </div>

                                                <hr /><hr />

                                            </div>


                                            <div class="vc_row large">

                                                <div class="row-half-color first half-white-section change-header-color" data-bgcolor="#fff"></div>
                                                <div class="row-half-color second half-dark-section" data-bgcolor="#202020"></div>

                                                <figure class="has-animation has-scale-vertical" data-delay="0">
                                                    <img src="images/projects/cairs02.jpg" alt="Image" />
                                                </figure>

                                            </div>


                                            <div class="vc_row row_padding_top dark-section" data-bgcolor="#202020">

                                                <div class="one_half">
                                                    <h4 class="has-mask" data-delay="0">Universal design for daily use</h4>
                                                    <p class="has-animation" data-delay="400">It functions as both a humidifier and dehumidifier. It automatically recognizes the humidity in real time to dehumidify the air when the humidity is high and humidify the air when the humidity is low.</p>
                                                </div>

                                                <div class="one_half last"></div>

                                                <hr /><hr /><hr />

                                            </div>


                                            <div class="vc_row dark-section large" data-bgcolor="#202020">

                                                <figure class="has-animation has-scale-vertical" data-delay="0">
                                                    <img src="images/projects/cairs03.jpg" alt="Image" />
                                                </figure>

                                            </div>


                                            <div class="vc_row small text-align-center row_padding_top row_padding_bottom dark-section" data-bgcolor="#202020">

                                                <h1 class="has-mask">International Design Excellence Awards Bronze Prize</h1>
                                                <p class="has-animation" data-delay="200">United Stated of America, March 26th 2015</p>

                                            </div>




                                        </div>

                                        <div id="project-nav" class="app-canvas">
                                            <div class="next-project-wrap">
                                                <div class="next-project-title">
                                                    <div class="inner">
                                                        <div id="itemsWrapperLinks">
                                                            <div id="itemsWrapper">
                                                                <div class="item disable-drag active">
                                                                    <div class="item-appear">
                                                                        <div class="item-content">
                                                                            <a class="item-wrap ajax-link-project" data-type="page-transition" href="project01.html"></a>
                                                                            <div class="item-wrap-image"><img class="item-image grid__item-img" src="images/01hero.jpg" data-firstline="Next" data-secondline="Project" alt="" /></div>
                                                                            <img class="grid__item-img grid__item-img--large" src="images/01hero.jpg" />
                                                                        </div>
                                                                        <div class="item-caption">
                                                                            <h2 class="item-title">Placeholder</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="marquee-wrapper">
                                                            <div class="next-title-marquee" data-text="Placeholder">Placeholder</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <footer class="hidden">
                                    <div id="footer-container">

                                        <div id="backtotop" class="button-wrap left">
                                            <div class="icon-wrap parallax-wrap">
                                                <div class="button-icon parallax-element">
                                                    <i class="fa fa-angle-up"></i>
                                                </div>
                                            </div>
                                            <div class="button-text sticky left"><span data-hover="Back Top">Back Top</span></div>
                                        </div>

                                        <div class="footer-middle">
                                            <div class="copyright">2020 © <a class="link" target="_blank" href="https://www.gerardomestanza.com/">GerardoM.</a> All rights reserved.</div>
                                        </div>

                                        <div class="socials-wrap">
                                            <div class="socials-icon"><i class="fa fa-share-alt" aria-hidden="true"></i></div>
                                            <div class="socials-text">Contact Me</div>
                                            <ul class="socials">
                                                <li><span class="parallax-wrap"><a class="parallax-element" href="https://github.com/Gmes23" target="_blank">Git</a></span></li>
                                                <li><span class="parallax-wrap"><a class="parallax-element" href="https://stackoverflow.com/users/7221832/g-mestanza" target="_blank">SO</a></span></li>
                                                <li><span class="parallax-wrap"><a class="parallax-element" href="https://www.linkedin.com/in/gerardomestanza/" target="_blank">LNKD</a></span></li>
                                            </ul>
                                        </div>

                                    </div>
                                </footer>

                            </div>

                            <div id="app"></div>



                        </div>

                    </div>
                </main>




            </div>
        )
    }
}

export default ProjectSix